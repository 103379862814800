












import { Component, Vue } from 'vue-property-decorator'
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";

@Component({
  components: {ResponsiveContainer}
})
export default class HomePage extends Vue {

}
